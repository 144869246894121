import "./mercearia.css";
import biscoitoAguaSalTriunfo345g from "./mercearia-images/biscoitoAguaSalTriunfo345g.png";
import biscoitoNikitoRecheadoChocolatoMorango from "./mercearia-images/biscoitoNikitoRecheadoChocolatoMorango.png";
import biscoitoSalgadoSolSaltPlus380g from "./mercearia-images/biscoitoSalgadoSolSaltPlus380g.png";
import biscoitoTortuguitaRecheadoSabores120g from "./mercearia-images/biscoitoTortuguitaSabores120g.png";
import biscoitoPiraqueCookiesChocolateOriginal80g from "./mercearia-images/biscoitoPiraqueCookiesChocolateOriginal80g.png";
import biscoitoTortuguitaMaizenaLeite170g from "./mercearia-images/biscoitoTortuguitaMaizenaLeite170g.png";
import leiteCondensadoTriangulo395g from "./mercearia-images/leiteCondensadoTriangulo395g.png";
import cremeLeiteNestle200g from "./mercearia-images/cremeLeiteNestle200g.png";
import doceLeiteDonLeche420g from "./mercearia-images/doceLeiteDonLeche420g.png";
import chocolateVariadosTortuguitaArcor146g from "./mercearia-images/chocolateVariadosTortuguitaArcor146g.png";
import salgadinhosTakisSabores56g from "./mercearia-images/salgadinhosTakisSabores56g.png";
import RefrescoFriscoSabores18g from "./mercearia-images/RefrescoFriscoSabores18g.png";
import gelatinaSolSabores20g from "./mercearia-images/gelatinaSolSabores20g.png";
import cafeSafraTradicional500g from "./mercearia-images/cafeSafraTradicional500g.png";
import cafeAltinopolisPremium500g from "./mercearia-images/cafeAltinopolisPremium500g.png";
import achocolatadoToddyOriginal750g from "./mercearia-images/achocolatadoToddyOriginal750g.png";
import achocolatadoChocolatto700g from "./mercearia-images/achocolatadoChocolatto700g.png";
import amacianteDownyConcentrado500ml from "./mercearia-images/amacianteDownyConcentrado500ml.png";
import sabaoemPoSurf5em1_800g from "./mercearia-images/sabaoemPoSurf5em1_800g.png";
import sabaoPoTixan800G from "./mercearia-images/sabaoPoTixan800G.png";
import detergenteYpe500ml from "./mercearia-images/detergenteYpe500ml.png";
import limpadorMultiUsoLimpol500ml from "./mercearia-images/limpadorMultiUsoLimpol500ml.png";
import aguaSanirariaTriex1Lt from "./mercearia-images/aguaSanirariaTriex1Lt.png";
import detergenteLimpol from "../../assets/detergente-limpol.png";
import sodaCausticaIndaia1Kg from "./mercearia-images/sodaCausticaIndaia1Kg.png";
import papelHigienicoSulleg from "./mercearia-images/papelHigienicoSulleg.png";
import sabaoEmPastaDipol500g from "./mercearia-images/sabaoEmPastaDipol500g.png";
import flotadorPingoMagico from "../../assets/flotador-pingo-magico.png";
import bolachaMarilan from "../../assets/bolacha-maizena-marilan-chocolate.png";
import bolachaTrakinas from "../../assets/bolacha-trakinas-morango.png";
import bolachaPassatempo from "../../assets/bolacha-passatempo.png";
import fraldaDescartavelEvolutionXG from "./mercearia-images/fraldaDescartavelEvolutionXG.png";
import absorventeIntimusGel8unid from "./mercearia-images/absorventeIntimusGel8unid.png";
import shampooElseve200ml from "./mercearia-images/shampooElseve200ml.png";
import condicionadorElseve200ml from "./mercearia-images/condicionadorElseve200ml.png";
import panteneCondicionador150ml from "./mercearia-images/panteneCondicionador150ml.png";
import listerineCoolMintEnxaguanteBucal500ml from "./mercearia-images/listerineCoolMintEnxaguanteBucal500ml.png";
import cremeDentalOralB70g from "./mercearia-images/cremeDentalOralB70g.png";
import aparelho_de_Barbear_Gillette_Prestobarba3 from "./mercearia-images/aparelho_de_Barbear_Gillette_Prestobarba3.png";
import arrozDuSafra5kgTipo1 from "./mercearia-images/arrozDuSafra5kgTipo1.png";
import maioneseSoyaCaseiraPote500Gr from "./mercearia-images/maioneseSoyaCaseiraPote500Gr.png";
import milhoVerdeemConservaueroLata170g from "./mercearia-images/milhoVerdeemConservaueroLata170g.png";
import macarraoSemoladoBasilarEspaguete400g from "./mercearia-images/macarraoSemoladoBasilarEspaguete400g.png";
import aveiaNestleFlocos from "../../assets/aveia-flocos-nestle.png";
import chaMateLeao from "../../assets/chaMatteLeaoSabores10g.png";
import queijoParmesãoReliquiaSitio50gRalado from "./mercearia-images/queijoParmesãoReliquiaSitio50gRalado.png";
import feijaoCariocaTipo1DuSafraFeijãoCarioca from "./mercearia-images/feijaoCariocaTipo1DuSafraFeijãoCarioca.png";
import vinagreCasteloAlcoolColorido750ml from "./mercearia-images/vinagreCasteloAlcoolColorido750ml.png";
import seletaLegumes from "../../assets/seletaLegumes.png";
import filtrocafepacaembu103 from "../../assets/filtro-cafe-pacaembu-103.png";
import ketchupHeinz from "../../assets/ketchupHeinz.png";
import arrozPreferencia5KG from "../../assets/arrozPreferencia.png";
import feijaoPatureba1kg from "../../assets/feijaoPatureba1kg.png";
import vinagreCristal from "../../assets/vinagreCristal.png";
import oleoAzeiteLisboa from "../../assets/oleoAzeiteLisboa.png";
import gilletePrestobarba from "../../assets/gilletePrestobarba.png";
import OldSpiceVip72h from "../../assets/spice-90g.png";
import vejaMultiuso from "../../assets/vejaMultiuso.png";
import lustraMoveisPoliflor from "../../assets/lustraMoveisPoliflor.png";
import papelHigienicoNeve from "../../assets/papelHigienicoNeve.png";
import papelHigienicoMili from "../../assets/papel-higienic-mili-60m.png";
import papelHigienicoNeve4rolos from "../../assets/papel_higienico_neve_4rolos.png";
import tintaParaCabelo from "../../assets/tinta-para-cabelo.png";
import sacoLixoPoliluz from "../../assets/sacoLixoPoliluz.png";
import urcaLavaRoupas from "../../assets/urcaLavaRoupas.png";
import papel_aluminio_parana from "../../assets/papel_aluminio_parana.png";
import nissin_lamem_miojo from "../../assets/nissin_lamem_miojo.png";
import manteigaReliquia from "../../assets/manteiga-reliquia.png";
import alcool_fortys_92 from "../../assets/alcool_Fortys.png";
import tempero_tempero_pronto_tchetto from "../../assets/tempero_tempero_pronto_tchetto.png";
import sabaoPoSurf5Em1 from "../../assets/sabao-em-po-surf-5em1.png";
import amacianteRoupasUrca from "../../assets/amaciante-urca-2l.png";
import desifetanteUrca from "../../assets/desifetante-urca.png";
import farofa_yoki from "../../assets/farofa_temperada_yoki.png";
import farofa_zei from "../../assets/farofa_temperada_zei.png";
import lar_doce_lar_500ml from "../../assets/lar_doce_lar_500ml.png";
import saco_de_lixo_verom from "../../assets/saco_de_lixo_verom.png";
import sorvetePimpinella from "../../assets/sorvete-pimpinella.png";
import sucoBrassukSabores from "../../assets/suco-brassuk.png";
import absorveteAlways from "../../assets/absorvente-always.png";

function Mercearia() {
  return (
    <div>
      <div className="merceariaTitulo titulo-setor" id="mercearia">
        MERCEARIA
      </div>
      <div className="merceariaContent">
        <div className="container-ofertas promocoes-container">
          <div className="promo-item">
            <img
              src={sorvetePimpinella}
              alt="Sorvete Pimpinella Nozes | Flocos"
            />
            <p>Sorvete Pimpinella Sabores</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>29,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={aveiaNestleFlocos} alt="Aveia em Flocos | Nestlê" />
            <p>Aveia em Flocos | Nestlê</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={filtrocafepacaembu103}
              alt="Filtro de Papel Pacaembu 103"
            />
            <p>Filtro de Papel Pacaembu 103</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={chaMateLeao} alt="Chá Matte Leão 10G" />
            <p>Chá Matte Leão 10G</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={bolachaMarilan} alt="Bolacha Maizena Marilan Chocolate" />
            <p> Bolacha Maizena Marilan Chocolate</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img
              src={bolachaTrakinas}
              alt="Bolacha Maizena Marilan Chocolate"
            />
            <p> Bolacha TRakinas Morango</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,49
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={bolachaPassatempo} alt="Bolacha Passatempo Recheada" />
            <p> Bolacha Passatempo Recheada</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,49
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={manteigaReliquia} alt="Manteiga Relíquia do Sítio 200g" />
            <p> Manteiga Relíquia do Sítio 200g</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>16,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={sucoBrassukSabores} alt="Suco Brassuk Sabores" />
            <p>Suco em Pó Brassuk Sabores</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>0,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={papelHigienicoMili}
              alt="Papel Higiênico Mili 60 metros"
            />
            <p>Papel Higiênico Mili 60 metros</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={sabaoPoSurf5Em1} alt="Sabão em Pó Surf 5 em 1 800g" />
            <p>Sabão em Pó Surf 5 em 1 800g</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>12,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={amacianteRoupasUrca} alt="Amaciante de roupas Urca 2L" />
            <p>Amaciante de roupas Urca 2L</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={desifetanteUrca} alt="Desifetante Urca 500ml" />
            <p>Desifetante Urca 500ml</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,49
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={flotadorPingoMagico} alt="Flotador Pingo Mágico 1L" />
            <p> Flotador Pingo Mágico 1L REFIL</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>15,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={detergenteLimpol} alt="Detergente Limpol" />
            <p>Detergente Limpol</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,49
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={tintaParaCabelo} alt="Tintura para cabelos Cor & Ton" />
            <p>Tintura para cabelos Cor & Ton</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>14,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={OldSpiceVip72h}
              alt="Antitranspirante Spray Old Spice Vip 72h"
            />
            <p>Antitranspirante Spray Old Spice Vip 72h 90g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>14,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={absorveteAlways} alt="Absorvente Aways" />
            <p>Absorvente Aways</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>5,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mercearia;
