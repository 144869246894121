import "./bebidas.css";
import "../global.css";
import amstelFardoLata350ml from "./images-bebidas/amstelFardoLata350ml.png";
import cerveja_Spaten_lata from "./images-bebidas/cerveja_Spaten_lata.png";
import cristalLataFardo from "./images-bebidas/cervejaCristalLataFardo.png";
import budweiserFardolata350ml from "./images-bebidas/budweiserFardolata350ml.png";
import cervejaPuroMalte from "./images-bebidas/cervejaPuroMalte.png";
import cervejaLataImperio from "./images-bebidas/cervejaLataImperio.png";
import energeticoMonsterGreen from "./images-bebidas/energeticoMonsterGreen.png";
import cocaColaZero from "./images-bebidas/coca-cola-2lZeroAcucar.png";
import sucoDelValleSabores from "./images-bebidas/sucoDelValleSabores.png";
import guaranaFabri2l from "./images-bebidas/guaranaFabri2l.png";
import vinhoParatudo from "../../assets/vinhoParatudo.png";
import vinhoBordo from "../../assets/vinhoTintoBordo.png";
import pingaPirassununga from "../../assets/pingaPirassununga.png";
import pingaMoenda from "../../assets/pingaMoenda.png";
import corote_sabores from "../../assets/corote_sabores.png";
import guaranaMaca from "../../assets/guaranaMaca.png";
import aguaTonica from "../../assets/aguaTonica.png";
import vinho_mioranza from "../../assets/vinho_mioranza.png";
import vinho_novecento from "../../assets/vinho_novecento.png";
import vinho_d_ouro from "../../assets/vinho_d_ouro.png";
import energetico_vibe_1L from "../../assets/energetico_vibe_1L.png";
import aguaDeCocoPuroCoco from "../../assets/agua-de-coco-purp-coco.png";
import achocolatadoDanette from "../../assets/achocolatado-danette.png";
import askovRemix from "../../assets/asko-remix-900ml.png";

function Bebidas() {
  return (
    <div id="bebidas">
      <div className="bebidas titulo-setor">BEBIDAS</div>
      <div className="container-ofertas promocoes-container">
        <div className="promo-item">
          <img src={aguaTonica} alt="Água Tônica lata 350ml" />
          <p> Água Tônica lata 350ml </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>2,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img
            src={achocolatadoDanette}
            alt="Achocolatados Danette 200ml - Bebida Láctea Danone"
          />
          <p> Achocolatado Danette 200ml</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>2,49
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={vinho_d_ouro} alt="Vinho Real D'Ouro Tinto Suave" />
          <p> Vinho Real D'Ouro Tinto Suave 1L</p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>19,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={askovRemix} alt="VODKA ASKOV FRUTAS ROXAS 900 ML" />
          <p> VODKA ASKOV FRUTAS 900 ML </p>
          <div className="title-oferta-medio">
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>19,99
              <span className="valor-unidade">unid.</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bebidas;
